var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.priceQuotationsData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("PriceQuotations.number")))]),_c('th',[_vm._v(_vm._s(_vm.$t("PriceQuotations.code")))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.$t("PriceQuotations.clientName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.user")))]),_c('th',[_vm._v(_vm._s(_vm.$t("date")))]),_c('th',[_vm._v(_vm._s(_vm.$t("ConstantsListSelect.ReportTemplatesName")))]),_vm._m(0)])]),_c('tbody',_vm._l((_vm.priceQuotationsData),function(priceQuotation,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.filterData.currentIndex))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(priceQuotation.fullCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( priceQuotation.requestPlaceInfoData ? priceQuotation.requestPlaceInfoData.placeNameAr : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( priceQuotation.requestPlaceInfoData ? priceQuotation.requestPlaceInfoData.placeNameEn : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(priceQuotation.userCreatedData.userNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(priceQuotation.priceQuotationDateTimeCustomized))+" ")]),_c('td',{style:(("color: " + (priceQuotation.reportTemplateInfoData.reportTemplateColorHexFromat)))},[_vm._v(" "+_vm._s(_vm.isDataExist( priceQuotation.reportTemplateInfoData ? priceQuotation.reportTemplateInfoData .reportTemplateNameCurrent : "" ))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[(
                _vm.checkPrivilege(_vm.hasPriceQuotationJobOrderAdd()) &&
                priceQuotation.priceQuotationHistoryWorkTypeToken ==
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.WaitingForCustomerAccept
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotationJobOrders.add')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('PriceQuotationJobOrderAdd');}}},[_c('img',{attrs:{"src":require("@/assets/images/PriceQuotationJobOrdersW.svg")}})])]):_vm._e(),(
                _vm.checkPrivilege(
                  _vm.hasPriceQuotationSendPriceQuotationToClient()
                ) &&
                priceQuotation.priceQuotationHistoryWorkTypeToken ==
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.WaitingForSupplier
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.sendToClient')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('SendPriceQuotationToClient');}}},[_c('img',{attrs:{"src":require("@/assets/images/sendToClient.svg")}})])]):_vm._e(),(
                priceQuotation.priceQuotationHistoryWorkTypeToken ==
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.WaitingForCustomerAccept ||
                priceQuotation.priceQuotationHistoryWorkTypeToken ==
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.WaitingForSupplier
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.resendToManyEmployeesClient')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('ResendToManyClients');}}},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})])]):_vm._e(),(
                _vm.checkPrivilege(_vm.hasPriceQuotationResendPriceQuotations()) &&
                priceQuotation.priceQuotationHistoryWorkTypeToken ==
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.WaitingForCustomerAccept
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.resendToClient')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('ResendPriceQuotationToClient');}}},[_c('img',{attrs:{"src":require("@/assets/images/email.svg")}})])]):_vm._e(),(_vm.hasPriceQuotationMoveTo)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.PriceQuotationMove",modifiers:{"PriceQuotationMove":true}}],attrs:{"title":_vm.$t('PriceQuotations.moveTo')},on:{"click":function($event){return _vm.setPriceQuotationData(priceQuotation)}}},[_c('img',{attrs:{"src":require("@/assets/images/EducationalGroupTransfers.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.report')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('PriceQuotationReport');}}},[_c('img',{attrs:{"src":require("@/assets/images/report.svg")}})])]),(
                _vm.checkPrivilege(_vm.hasPriceQuotationReportBill()) &&
                priceQuotation.priceQuotationHistoryWorkTypeToken !=
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.NewlyWanted &&
                priceQuotation.priceQuotationHistoryWorkTypeToken !=
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.WaitingForSupplier
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.reportBill')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('PriceQuotationReportBill');}}},[_c('img',{attrs:{"src":require("@/assets/images/receipt.svg")}})])]):_vm._e(),(
                _vm.checkPrivilege(_vm.hasPriceQuotationEdit()) &&
                priceQuotation.priceQuotationHistoryWorkTypeToken !=
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.Canceled
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('PriceQuotationUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasPriceQuotationAdd()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('duplicate')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('PriceQuotationDuplicate');}}},[_c('img',{attrs:{"src":require("@/assets/images/duplicate.svg")}})])]):_vm._e(),(
                priceQuotation.priceQuotationHistoryWorkTypeToken !=
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.Canceled &&
                priceQuotation.priceQuotationHistoryWorkTypeToken !=
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.NewlyWanted
              )?_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'PriceQuotationInfo',
                  params: { token: priceQuotation.priceQuotationToken },
                },"title":_vm.$t('PriceQuotationsInfo.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/PriceQuotationsInfo.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('PriceQuotationInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),(
                _vm.checkPrivilege(_vm.hasPriceQuotationChangeDateTime()) &&
                priceQuotation.priceQuotationHistoryWorkTypeToken !=
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.Canceled
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.changeDateAndTime')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('PriceQuotationChangeDateAndTime');}}},[_c('img',{attrs:{"src":require("@/assets/images/dateAndTime.svg")}})])]):_vm._e(),(
                _vm.checkPrivilege(_vm.hasPriceQuotationMoveToWaitingForSupplier()) &&
                priceQuotation.priceQuotationHistoryWorkTypeToken !=
                  _vm.PRICE_QUOTATION_HISTORY_WORK_TYPES.Canceled
              )?_c('li',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.SendRequestProductItemReport')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('SendRequestProductItemReport');}}},[_c('img',{attrs:{"src":require("@/assets/images/send.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setPriceQuotationData(priceQuotation);
                  _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }