var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'SendRequestProductItemReport',"size":"xl","headerText":_vm.$t('PriceQuotations.SendRequestProductItemReport'),"headerIcon":_vm.sendRequestProductItemReport.icon},on:{"opened":function($event){return _vm.getDialogs()}}},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBoxMultiple',{attrs:{"className":'col-md-12',"id":"priceQuotationDetailsTokens","value":_vm.sendRequestProductItemReport.priceQuotationDetailsTokens,"options":_vm.priceQuotationDetailsStoreItemOptions,"title":_vm.$t('StoreItems.select'),"imgName":'StoreItems.svg',"returnArrayOfObjects":false},on:{"changeValue":function($event){_vm.sendRequestProductItemReport.priceQuotationDetailsTokens = $event;
            _vm.exportRequestProductItemReport.priceQuotationDetailsTokens =
              $event;
            _vm.getSuppliersCanWorkInPriceQuotation();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":"sendRequest-reportTemplateToken","errors":_vm.errors_reportTemplateToken,"value":_vm.sendRequestProductItemReport.reportTemplateToken,"options":_vm.reportTemplateTokenOptions,"title":_vm.$t('ConstantsListSelect.ReportTemplates'),"imgName":'ReportTemplates.svg'},on:{"changeValue":function($event){_vm.sendRequestProductItemReport.reportTemplateToken = $event;
            _vm.exportRequestProductItemReport.reportTemplateToken = $event;

            _vm.$v.sendRequestProductItemReport.reportTemplateToken.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":"sendRequest-emailTemplateToken","emitObject":true,"value":_vm.sendRequestProductItemReport.emailTemplateToken,"options":_vm.emailTemplateTokenOptions,"title":_vm.$t('EmailTemplates.select'),"imgName":'email.svg'},on:{"changeValue":function($event){_vm.sendRequestProductItemReport.emailTemplateToken = $event.value;
            _vm.exportRequestProductItemReport.emailTemplateToken = $event.value;
            _vm.sendRequestProductItemReport.emailHtmlBody = $event.body;
            _vm.exportRequestProductItemReport.emailHtmlBody = $event.body;
            _vm.sendRequestProductItemReport.emailHtmlSignature =
              $event.signature;
            _vm.exportRequestProductItemReport.emailHtmlSignature =
              $event.signature;}}})],1),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[(_vm.placeTokensOptions.length > 0)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAll),expression:"selectedAll"}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"sendRequest-selectAll"},domProps:{"checked":Array.isArray(_vm.selectedAll)?_vm._i(_vm.selectedAll,null)>-1:(_vm.selectedAll)},on:{"click":function($event){return _vm.selectAll()},"change":function($event){var $$a=_vm.selectedAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedAll=$$c}}}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("general.all"))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t("suppliers.name")))]),(
                    _vm.checkPrivilege(
                      _vm.hasPriceQuotationDetailExportRequestProductItemReport()
                    )
                  )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("PriceQuotations.ExportRequestProductItemReport"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.placeTokensOptions),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendRequestProductItemReport.placeTokens),expression:"sendRequestProductItemReport.placeTokens"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"value":item.value,"checked":Array.isArray(_vm.sendRequestProductItemReport.placeTokens)?_vm._i(_vm.sendRequestProductItemReport.placeTokens,item.value)>-1:(_vm.sendRequestProductItemReport.placeTokens)},on:{"click":function($event){return _vm.select(item.value)},"change":function($event){var $$a=_vm.sendRequestProductItemReport.placeTokens,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendRequestProductItemReport, "placeTokens", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendRequestProductItemReport, "placeTokens", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendRequestProductItemReport, "placeTokens", $$c)}}}})]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(item.placeNameCurrent)))]),(
                    _vm.checkPrivilege(
                      _vm.hasPriceQuotationDetailExportRequestProductItemReport()
                    )
                  )?_c('td',[_c('button',{attrs:{"title":_vm.$t('PriceQuotations.ExportRequestProductItemReport')},on:{"click":function($event){$event.preventDefault();return _vm.exportRequest(item.value)}}},[_c('img',{attrs:{"src":require("@/assets/images/report.svg")}})])]):_vm._e()])}),0)])])]],2),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.sendRequest.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.$t('PriceQuotations.SendRequestProductItemReport')}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:("ConfirmCloseSheet-SendRequestProductItemReport"),expression:"`ConfirmCloseSheet-SendRequestProductItemReport`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }