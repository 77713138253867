var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"row mb-4"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),(_vm.showInReportStatus)?_c('th',[_vm._v(_vm._s(_vm.$t("general.show")))]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t("delete")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("PropertyTypes.listItems.name"))+" ")]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("PropertyTypes.listItems.defaultValue"))+" ")])])]),_c('tbody',[(_vm.propertyDetailsData.length == 0)?[_c('tr',[_c('th',{attrs:{"colspan":_vm.showInReportStatus ? 5 : 4}},[_vm._v(" "+_vm._s(_vm.$t("PropertyTypes.listItems.noItems"))+" ")])])]:_vm._l((_vm.propertyDetailsData),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),(_vm.showInReportStatus)?_c('td',[_c('CustomCheckbox',{attrs:{"value":item.showInReportStatus,"centerStatus":true,"withOutTitle":true},on:{"changeValue":function($event){item.showInReportStatus = $event}}})],1):_vm._e(),_c('td',[_c('button',{attrs:{"title":_vm.$t('delete')},on:{"click":function($event){$event.preventDefault();return _vm.removeSlice(index)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]),_c('td',[_c('CustomInput',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyNameEn]"),"isDisabled":true,"value":item.propertyNameEn
                    ? item.propertyNameEn
                    : item.propertyNameAr,"withOutDesign":true},on:{"changeValue":function($event){item.propertyNameEn = $event}}})],1),(item.propertyFieldTypeToken == _vm.PROPERTY_FIELD_TYPES.Text)?[_c('td',[_c('CustomInput',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueEn]"),"value":item.propertyFieldDefaultValueEn ||
                    item.propertyFieldDefaultValueAr,"withOutDesign":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueEn = $event;
                    item.propertyFieldDefaultValueAr = $event;}}})],1)]:(
                item.propertyFieldTypeToken ==
                _vm.PROPERTY_FIELD_TYPES.DropDownList
              )?[_c('td',[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{directives:[{name:"show",rawName:"v-show",value:(_vm.showParent),expression:"showParent"}],attrs:{"className":'col-md-6',"id":(_vm.id + "-table-[" + index + "][dropDownListToken]"),"value":item.dropDownListToken,"options":_vm.dropDownListTokenOptions,"title":_vm.$t('DropDownLists.select'),"imgName":'DropDownLists.svg',"openDown":true},on:{"changeValue":function($event){item.dropDownListToken = $event;
                      item.propertyFieldDefaultValueEn = $event;
                      item.propertyFieldDefaultValueAr = $event;
                      _vm.dropDownListTokenChanged($event, index);}}}),_c('CustomSelectBox',{key:_vm.keySelect,attrs:{"className":'col-md-12',"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueToken]"),"value":item.propertyFieldDefaultValueToken,"options":item.defaultValueOptions,"title":_vm.$t('PropertyTypes.listItems.defaultValue'),"imgName":'DropDownLists.svg',"openDown":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueToken = $event}}})],1)])]:[_c('td',[(
                    item.propertyFieldTypeToken ==
                      _vm.PROPERTY_FIELD_TYPES.Email ||
                    item.propertyFieldTypeToken == _vm.PROPERTY_FIELD_TYPES.Link
                  )?_c('CustomInput',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueEn]"),"value":item.propertyFieldDefaultValueEn ||
                    item.propertyFieldDefaultValueAr,"withOutDesign":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueAr = $event;
                    item.propertyFieldDefaultValueEn = $event;}}}):(
                    item.propertyFieldTypeToken ==
                      _vm.PROPERTY_FIELD_TYPES.Number ||
                    item.propertyFieldTypeToken ==
                      _vm.PROPERTY_FIELD_TYPES.Decimal ||
                    item.propertyFieldTypeToken ==
                      _vm.PROPERTY_FIELD_TYPES.Percentage
                  )?_c('CustomInputFloat',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueAr]"),"value":item.propertyFieldDefaultValueEn ||
                    item.propertyFieldDefaultValueAr,"withOutDesign":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueAr = $event;
                    item.propertyFieldDefaultValueEn = $event;}}}):(
                    item.propertyFieldTypeToken ==
                    _vm.PROPERTY_FIELD_TYPES.Integer
                  )?_c('CustomInputInt',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueAr]"),"value":item.propertyFieldDefaultValueEn ||
                    item.propertyFieldDefaultValueAr,"title":_vm.$t('PropertyTypes.listItems.defaultValue'),"imgName":'PropertyTypes.svg',"withOutDesign":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueAr = $event;
                    item.propertyFieldDefaultValueEn = $event;}}}):(
                    item.propertyFieldTypeToken ==
                    _vm.PROPERTY_FIELD_TYPES.DateTime
                  )?_c('DateTimePicker',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueAr]"),"type":"dateTime","value":item.propertyFieldDefaultValueEn ||
                    item.propertyFieldDefaultValueAr,"title":_vm.$t('PropertyTypes.listItems.defaultValue'),"language":_vm.language,"withOutDesign":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueAr = $event.dateTime;
                    item.propertyFieldDefaultValueEn = $event.dateTime;}}}):(
                    item.propertyFieldTypeToken == _vm.PROPERTY_FIELD_TYPES.Date
                  )?_c('DateTimePicker',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueAr]"),"type":"date","value":item.propertyFieldDefaultValueEn ||
                    item.propertyFieldDefaultValueAr,"title":_vm.$t('PropertyTypes.listItems.defaultValue'),"language":_vm.language,"imgName":'date.svg',"withOutDesign":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueAr = $event.date;
                    item.propertyFieldDefaultValueEn = $event.date;}}}):(
                    item.propertyFieldTypeToken == _vm.PROPERTY_FIELD_TYPES.Time
                  )?_c('DateTimePicker',{attrs:{"id":(_vm.id + "-table-[" + index + "][propertyFieldDefaultValueAr]"),"type":"time","value":item.propertyDateTime,"title":_vm.$t('PropertyTypes.listItems.defaultValue'),"language":_vm.language,"imgName":'time.svg',"withOutDesign":true},on:{"changeValue":function($event){item.propertyDateTime = $event.dateTime;
                    item.propertyFieldDefaultValueAr = $event.timeCustomized;
                    item.propertyFieldDefaultValueEn = $event.timeCustomized;}}}):(
                    item.propertyFieldTypeToken ==
                    _vm.PROPERTY_FIELD_TYPES.Logical
                  )?_c('CustomCheckbox',{attrs:{"value":item.propertyFieldDefaultValueEn ||
                    item.propertyFieldDefaultValueAr,"centerStatus":true,"withOutTitle":true},on:{"changeValue":function($event){item.propertyFieldDefaultValueAr = "" + $event;
                    item.propertyFieldDefaultValueEn = "" + $event;}}}):_c('span',[_vm._v("-")])],1)]],2)})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }